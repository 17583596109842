/* eslint-disable no-continue */
/* eslint-disable no-restricted-syntax */
import {
  Backdrop,
  Box,
  CircularProgress,
  colors,
  Container,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import React, { useEffect, useState } from 'react';
import Requests from '../../../BackofficeRequests/Requests';
import Page from '../../../components/Page';
import Color from '../../../utils/Color';
import MultiLineGraph from '../components/MultiLineGraph';
import PieChartCard from '../components/PieChartCard';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
}));

const processData = (events, firstDay, days) => {
  let _events = [];
  if (events.length > 0) {
    _events = events;
  } else {

    const numberEvents = parseInt(Math.random() * (4000 - 300) + 300);
    console.log("NUMBER FAKE EVENTS", numberEvents);
    let dayIndex = 0;
    for (let i = 0; i < numberEvents; i++) {
      const randomChannel = parseInt(Math.random() * 100);
      const randomType = parseInt(Math.random() * 100);
      const day = new Date(firstDay);
      if ((dayIndex + 1) === 8) {
        dayIndex = 0;
      } else {
        dayIndex++;
      }
      day.setDate(firstDay.getDate() + dayIndex);
      let _channel = { id: "62449af5133ce358e09fa9e0", name: "Kineton ATSC" };
      if (randomChannel > 25 && randomChannel < 50) {
        _channel = { id: "624c99ee133ce358e09fab04", name: "Run3TV Channel" }
      } else if (randomChannel > 50 && randomChannel < 75) {
        _channel = { id: "624c9b12133ce358e09fab0a", name: "KTNV" }
      }
      _events.push({
        channel: _channel,
        date: day,
        type: randomType > 50 ? "impression" : "interaction",
        vendor: i % 2 === 0 ? "SAMSUNG" : i % 3 === 0 ? "PANASONIC" : i % 5 === 0 ? "HISENSE" : "LGE",
        region: i % 5 === 0 ? "Molise" : i % 7 === 0 ? "Umbria" : i % 2 === 0 ? "Lombardia" : i % 3 === 0 ? "Piemonte" : "Campania"
      })
    }
  }
  console.log("PROCESS DATA DASHBOARD", { _events, firstDay, days });
  const chImp = new Map();
  const chInter = new Map();
  const channels = new Map();
  const dayIndex = new Map();
  const vendors = { imp: new Map(), inter: new Map() };
  const regions = { imp: new Map(), inter: new Map() };

  for (let i = 0; i < days; i++) {
    const day = new Date(firstDay);
    day.setDate(firstDay.getDate() + i);
    const dl = day.toLocaleString('it-IT', {
      year: 'numeric',
      month: '2-digit',
      day: 'numeric'
    });
    dayIndex.set(dl, i);
  }
  for (const {
    channel, date, type, vendor, region
  } of _events) {
    if (!channel) continue;
    if (type === 'impression') {
      if (!chImp.has(channel.id)) {
        chImp.set(channel.id, new Array(days).fill(0));
        channels.set(channel.id, channel.name);
      }
      const evDate = new Date(date);
      const dl = evDate.toLocaleString('it-IT', {
        year: 'numeric',
        month: '2-digit',
        day: 'numeric'
      });
      chImp.get(channel.id)[dayIndex.get(dl)] += 1;
      if (vendors.imp.has(vendor)) vendors.imp.set(vendor, vendors.imp.get(vendor) + 1);
      else vendors.imp.set(vendor, 1);
      if (regions.imp.has(region)) regions.imp.set(region, regions.imp.get(region) + 1);
      else regions.imp.set(region, 1);
    } else if (type === 'interaction') {
      if (!chInter.has(channel.id)) {
        chInter.set(channel.id, new Array(days).fill(0));
        channels.set(channel.id, channel.name);
      }
      const evDate = new Date(date);
      const dl = evDate.toLocaleString('it-IT', {
        year: 'numeric',
        month: '2-digit',
        day: 'numeric'
      });
      chInter.get(channel.id)[dayIndex.get(dl)] += 1;
      if (vendors.inter.has(vendor)) vendors.inter.set(vendor, vendors.inter.get(vendor) + 1);
      else vendors.inter.set(vendor, 1);
      if (regions.inter.has(region)) regions.inter.set(region, regions.inter.get(region) + 1);
      else regions.inter.set(region, 1);
    }
  }
  return {
    chImp,
    chInter,
    channels,
    dayIndex,
    vendors,
    regions
  };
};

const Dashboard = () => {
  const { t, i18n } = useTranslation();

  const classes = useStyles();
  const [impressions, setImpressions] = useState({});
  const [interactions, setInteractions] = useState({});
  const [vendImp, setVendImp] = useState({});
  const [regImp, setRegImp] = useState({});
  const [vendInter, setVendInter] = useState({});
  const [regInter, setRegInter] = useState({});
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [weekImp, setWeekImp] = useState(0);
  const [weekInter, setWeekInter] = useState(0);

  const setDataset = (setter, map) => {
    const x = Array.from(map.entries()).sort(([, v1], [, v2]) => v2 - v1);
    const other = x.splice(5);
    if (other.length > 0) x.push(other.reduce(([, v1], [, v2]) => ['Altro', v1 + v2]));
    const col = new Color();
    setter({
      datasets: [
        {
          data: x.map(([, v]) => v),
          borderWidth: 6,
          backgroundColor: Array.from(col.genColors(map.size)),
          borderColor: colors.common.white,
          hoverBorderColor: colors.common.white,
        },
      ],
      labels: x.map(([k, _]) => k),
    });
  };

  const getSdEd = (weekAgo) => {
    const [sd, ed] = [new Date(), new Date()];
    sd.setDate(sd.getDate() - 7 * weekAgo + 1 - sd.getDay());
    ed.setDate(ed.getDate() - 7 * weekAgo + 7 - ed.getDay());
    sd.setHours(0, 0, 0, 0);
    ed.setHours(23, 59, 59, 59);
    return { sd, ed };
  };

  const getDateRangeString = (weekAgo) => {
    const { sd, ed } = getSdEd(weekAgo);
    return `${t('DAL')} ${sd.toLocaleDateString()} ${t('AL')} ${ed.toLocaleDateString()}`;
  };

  const fetchEvents = async (imp = true, inter = true, weekAgo = 0) => {
    setOpenBackdrop(true);
    try {
      const { sd, ed } = getSdEd(weekAgo);
      const { data } = await Requests.instance.analyticsEvents(sd, ed);
      const {
        chImp, chInter, channels, dayIndex,
        vendors, regions
      } = processData([], sd, 7);
      let col = new Color();
      if (imp) {
        const dImp = {};
        dImp.datasets = [];
        dImp.labels = Array.from(dayIndex.keys());
        for (const [key, val] of chImp.entries()) {
          dImp.datasets.push({ data: val, label: channels.get(key), backgroundColor: col.color });
        }
        setImpressions(dImp);
        setDataset(setVendImp, vendors.imp);
        setDataset(setRegImp, regions.imp);
      }
      if (inter) {
        const dInter = {};
        dInter.datasets = [];
        dInter.labels = Array.from(dayIndex.keys());
        col = new Color();
        for (const [key, val] of chInter.entries()) {
          dInter.datasets.push({ data: val, label: channels.get(key), backgroundColor: col.color });
        }
        setInteractions(dInter);
        setDataset(setVendInter, vendors.inter);
        setDataset(setRegInter, regions.inter);
      }
      setOpenBackdrop(false);
    } catch (err) {
      console.log("events");
      alert(err.message);
      setOpenBackdrop(false);
    }
  };

  useEffect(() => {
    fetchEvents();
  }, []);

  return (
    <Page
      className={classes.root}
      title="Dashboard"
    >
      <Backdrop open={openBackdrop} className={classes.backdrop}>
        <CircularProgress color="primary" />
      </Backdrop>
      <Container>
        {/* <Button onClick={async () => {
          try {
            await generateEvents();
            alert('done');
          } catch (err) {
            alert(err.message);
          }
        }}
        >
          Genera
      </Button> */}
        <Grid
          container
          spacing={3}
          justifyContent="center"
        >
          <Grid item sm={12}>
            <MultiLineGraph
              title="Impressions"
              dataset={impressions}
              action={(
                <Box display="flex" alignItems="center">
                  <IconButton
                    onClick={() => {
                      setWeekImp(weekImp + 1);
                      fetchEvents(true, false, weekImp + 1);
                    }}
                    size="large">
                    <NavigateBeforeIcon />
                  </IconButton>
                  <Typography>{getDateRangeString(weekImp)}</Typography>
                  <IconButton
                    onClick={() => {
                      setWeekImp(weekImp - 1);
                      fetchEvents(true, false, weekImp - 1);
                    }}
                    size="large">
                    <NavigateNextIcon />
                  </IconButton>
                </Box>
              )}
            />
          </Grid>
          <Grid item sm={5}>
            <PieChartCard
              title="Vendors impressions"
              dataset={vendImp}
            />
          </Grid>
          <Grid item sm={7}>
            <PieChartCard
              title="Regions impressions"
              dataset={regImp}
            />
          </Grid>
          <Grid item sm={12}>
            <MultiLineGraph
              title="Interactions"
              dataset={interactions}
              action={(
                <Box display="flex" alignItems="center">
                  <IconButton
                    onClick={() => {
                      setWeekInter(weekInter + 1);
                      fetchEvents(false, true, weekInter + 1);
                    }}
                    size="large">
                    <NavigateBeforeIcon />
                  </IconButton>
                  <Typography>{getDateRangeString(weekInter)}</Typography>
                  <IconButton
                    onClick={() => {
                      setWeekInter(weekInter - 1);
                      fetchEvents(false, true, weekInter - 1);
                    }}
                    size="large">
                    <NavigateNextIcon />
                  </IconButton>
                </Box>
              )}
            />
          </Grid>
          <Grid item sm={5}>
            <PieChartCard
              title="Vendors interactions"
              dataset={vendInter}
            />
          </Grid>
          <Grid item sm={7}>
            <PieChartCard
              title="Regions interactions"
              dataset={regInter}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default Dashboard;
